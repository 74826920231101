import { createRouter, createWebHashHistory } from 'vue-router';
import home from "../views/Home.vue";
import contact from "../views/ContactUs.vue";
import exams from "../views/Exams.vue";
import team from "../views/Team.vue";
import faq from "../views/FAQ.vue"; 
import intermediary from "../views/Intermediary.vue"
import general from "../views/General.vue"
import echo from "../views/EchoDoppler.vue"
import osteo from "../views/Osteo.vue"
import ImagerieSein from "../views/ImagerieSein.vue"
import ImagerieDent from "../views/ImagerieDent.vue"
import IRM from "../views/IRM.vue"
import scanner from "../views/Scanner.vue"

const routes = [
  {
    path: "/",
    name: 'accueil',
    component: home,
  },
  {
    path: "/contact",
    name: 'contact',
    component: contact,
  },
  {
    path: "/examens",
    name: 'examens',
    component: exams,
  },
  {
    path: "/équipe",
    name: 'Notreéquipe',
    component: team,
  },
  {
    path: "/faq",
    name: 'FAQ',
    component: faq,
  },
  {
    path: "/Rendez-vous",
    name: 'Rendez-vous',
    component: intermediary,
  },
  {
    path: "/Radiographiegénerale",
    name: 'Radiographiegénerale',
    component: general,
  },
  {
    path: "/EchographieDoppler",
    name: 'EchographieDoppler',
    component: echo,
  },
  {
    path: "/Osteo",
    name: 'Osteo',
    component: osteo,
  },
  {
    path: "/ImagerieSein",
    name: 'ImagerieSein',
    component: ImagerieSein,
  },
  {
    path: "/ImagerieDent",
    name: 'ImagerieDent',
    component: ImagerieDent,
  },
  {
    path: "/IRM",
    name: 'IRM',
    component: IRM,
  },
  {
    path: "/scanner",
    name: 'scanner',
    component: scanner,
  },
  

];

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
