<template>
  <div class="hello">
    <h1>{{ msg }}</h1>
  </div>
</template>

<script>
export default {
  name: "TitleVue",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h1 {
  font-weight: 600;
  color: #766e6f;
  margin-top: 12%;
}
h3 {
  margin: 40px 0 0;
}
a {
  color: #2a1294;
}

@media screen and (max-width: 1250px) {

h1 {
  margin-top: 15%;
}
}

@media screen and (max-width: 960px) {

h1 {
  margin-top: 18%;
}
}

@media screen and (max-width: 550px) {

h1 {
  margin-top: 22%;
}
}
</style>
