<template>
  <img class="LogoImg" alt="IMS logo" src="../assets/IMS_Logo.png" />
  <TitleVue msg=""/>
  <div class="content">
    <h1 class="title">| Echographie et Doppler</h1>
    <ul class="ListContent">
      <li>
        <p>| Echographie hépatique, abdominale et élastographie</p>
      </li>
      <li>
        <p>| Echographie de la prostate, pelvienne, datation de grossesse</p>
      </li>
      <li>
        <p>| Echographie osteoarticulaire</p>
      </li>
      <li>
        <p>| Echographie pédiatrique</p>
      </li>
      <li>
        <p>| Doppler artériel et veineux</p>
      </li>
    </ul>
  </div>
  <div class="ContentImg">
    <div class="Image">
      <img alt="Doppler" src="../assets/Doppler.png" />
    </div>
    <div class="Image">
      <img alt="Echographie" src="../assets/Echo.png" />
    </div>
  </div>

  <div class="rdv">
    <router-link to="/Rendez-vous" class="rdvButton">
      Prendre un rendez-vous
    </router-link>
  </div>
</template>

<script>
import TitleVue from "../components/TitleVue.vue";

export default {
  name: "HomeView",
  components: {
    TitleVue,
  },
};
</script>

<style scoped lang="scss">
ul {
  list-style-type: none;
}

li {
  display: flex;
}

.rdv {
  margin-top: 5%;
}

.ContentImg {
  margin-top: 30px;
  display: flex;
}

.Image {
  img {
    height: 300px;
  }
}
</style>
