<template>
  <img class="LogoImg" alt="IMS logo" src="../assets/IMS_Logo.png" />
  <TitleVue msg=""/>
  <div class="content">
    <h1 class="title">| Ostéodensitométrie</h1>
    <div class="contentText">
      Examen permettant de dépister l'ostéoporose par la mesure de la densité
      minérale osseuse du squelette. Il est effectué en 15 minutes, très peu
      irradiant et non douloureux. Il est remboursé par la sécurité sociale sous
      conditions. Rendez-vous à prendre auprès de notre secrétariat
    </div>
    <div class="info">
      <a
        class="rdvButton"
        href="https://patient.radiologie.fr/fr/examens-en-pratique/exposition-rayonnements-ionisants"
      >
        En savoir plus
      </a>
    </div>
  </div>
  <div class="ContentImg">
    <div class="Image">
      <img alt="Doppler" src="../assets/densito.png" />
    </div>
    <div class="Image">
      <img alt="Echographie" src="../assets/densito2.png" />
    </div>
  </div>

  <div class="rdv">
    <router-link to="/Contact" class="rdvButton">
      Contacter le secrétariat
    </router-link>
  </div>
</template>

<script>
import TitleVue from "../components/TitleVue.vue";

export default {
  name: "HomeView",
  components: {
    TitleVue,
  },
};
</script>

<style scoped lang="scss">
.content {
}
.contentText {
  width: 80%;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

li {
  display: flex;
}

.rdv {
  margin-top: 5%;
}

.ContentImg {
  margin-top: 30px;
  display: flex;
}

.Image {
  img {
    height: 400px;
    width: 200px;
  }
}
</style>
