<template>
  <img class="LogoImg" alt="IMS logo" src="../assets/IMS_Logo.png" />
  <TitleVue msg=""/>
  <div class="content">
    <h1 class="title">| Radiographie Numérisée</h1>
    <ul class="ContentList">
      <li class="ListContent">
        <p>| Radiographie pulmonaire</p>
      </li>
      <li class="ListContent">
        <p>| Radiographie de l'abdomen</p>
      </li>
      <li class="ListContent">
        <p>
          | Radiographies des articulations, des membres supérieurs et
          inférieurs
        </p>
      </li>
      <li class="ListContent">
        <p>| Radiographie du rachis, Télérachis</p>
      </li>
      <li class="ListContent">
        <p>| Radiographie du crâne et des sinus...</p>
      </li>
      <li class="ListContent">
        <p>| Examens spéciaux pouvant nécessiter une préparation :</p>
      </li>
      <li>Transit oeso-gastro-duodénal (TOGD)</li>
      <li>Hystero-salpingographie</li>
      <li>Urographie intraveineuse</li>
    </ul>
  </div>
  <div class="ContentImg">
    <div class="Image">
      <img alt="Radiographie du thorax" src="../assets/RadioThorax.png" />
    </div>
    <div class="Image">
      <img alt="Radiographie de la main" src="../assets/Hand.png" />
    </div>
    <div class="Image">
      <img alt="Radiographie du genou gauche" src="../assets/GenouG.png" />
    </div>
  </div>

  <div class="rdv">
    <router-link to="/Rendez-vous" class="rdvButton">
      Prendre un rendez-vous
    </router-link>
  </div>
</template>

<script>
import TitleVue from "../components/TitleVue.vue";

export default {
  name: "HomeView",
  components: {
    TitleVue,
  },
};
</script>

<style scoped lang="scss">

ul {
  list-style-type: none;
}

.ListContent {
  display: flex;

}

.rdv {
  margin-top: 5%;
}

.ContentImg {
  margin-top: 30px;
  display: flex;
}

.Image {
  img {
    height: 300px;
  }
}


@media screen and (max-width: 960px) {

  .Image {
  img {
    height: 250px;
  }
}

@media screen and (max-width: 730px) {

.Image {
img {
  height: 130px;
}
}
}
}
</style>
