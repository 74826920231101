<script>
import Sidebar from "@/components/SideBar";
export default {
  components: { Sidebar },
  setup() {
    return { };
  },
};
</script>
<template>
  <Sidebar />
    <router-view />

</template>

<style>
#app {
  font-family: "Urbanist", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-left: 13%;
  text-align: left;
  color: #766e6f;
  font-weight: 500;
  background-color: rgba(0, 0, 0, 0);
  font-size: large;
  overflow-x: hidden;
}
#nav {
  padding: 30px;
}
#nav a {
  font-weight: bold;
  color: #2c3e50;
}
#nav a.router-link-exact-active {
  color: #2a1294;
}

.LogoImg {
  height: 120px;
  position: absolute;
  z-index: 1;
  top: 1%;
  right: 1%;
  bottom: 0;
}
.content {
  padding: 5px;

}
.rdv {
  margin-top: 40px;
}
.rdvButton {
  display: inline-block;
  margin-right: 10px;
  margin-top: 15px;
  background: #766e6f;
  color: #fff;
  text-decoration: none;
  font-size: 15px;
  line-height: 38px;
  padding-right: 10px;
  padding-left: 10px;

  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  text-align: center;
}

.rdvButton:hover {
  background: #fff;
  color: #766e6f;
  box-shadow: 0 4px 4px rgba(83, 100, 255, 0.32);
}

.infoButton {
  display: inline-block;
  margin-right: 10px;
  margin-top: 15px;
  background: #766e6f;
  color: #fff;
  text-decoration: none;
  font-size: 13px;
  line-height: 38px;
  border-radius: 50px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  width: 170px;
  text-align: center;
  appearance: none;
}

.infoButton:hover {
  background: #fff;
  color: #766e6f;
  box-shadow: 0 4px 4px rgba(83, 100, 255, 0.32);
}

@media screen and (max-width: 1250px) {

  .LogoImg {
    height: 90px;
}
}

@media screen and (max-width: 960px) {
#app {
  font-size: small;
  
}
.rdvButton {
  font-size: 12px;
}
.LogoImg {
  height: 50px;
}
}
@media screen and (max-width: 550px) {

  #app {
  margin-left: 15%;
  
}
.rdvButton {
  font-size: 10px;
  padding-right: 5px;
  padding-left: 5px;
}
.LogoImg {
  height: 30px;
}
}

@media screen and (max-width: 400px) {


.rdvButton {
margin-right: 5px;
margin-top: 15px;
font-size: 8px;
padding-right: 5px;
padding-left: 5px;
}

}

</style>
