<template>
  <div class="home">
    <img class="LogoImg" alt="IMS logo" src="../assets/IMS_Logo.png" />
    <TitleVue msg=""/>
    <div class="content">
        <div class="Image">
          <a href="https://www.google.fr/maps/place/Centre+Radiologie+Longchamp/@48.8697331,2.2222142,17z/data=!3m1!4b1!4m5!3m4!1s0x47e664daf2cdcac3:0xb9b896de59c1b447!8m2!3d48.8697296!4d2.2244029">
          <img alt="Plan d'accès" class="map" src="../assets/Plan.png" />
        </a>
        </div>
        <div class="info">
        <div class="tel">
          <ul>
            <h2>| Contact par téléphone :</h2>
            <li>Secrétariat : 01 45 06 11 10</li>
            <li>Fax : 01 45 06 61 34</li>
          </ul>
        </div>

        <div class="mail">
          <ul>
            <h2>| Contact Par E-Mail :</h2>
            <li>
              Pour vos demandes concernant les rendez-vous :
              SECRETARIAT@imslongchamp.fr
            </li>
            <li>Pour toute autre demande : centrelongchamp@imslongchamp.fr</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import TitleVue from "../components/TitleVue.vue";

export default {
  name: "HomeView",
  components: {
    TitleVue,
  },
};
</script>

<style scoped lang="scss">
li {
  list-style-type: none;
}
.map {
  width: 45%;
}


@media screen and (max-width: 1250px) {
  .map {
  width: 70%;
}
}

@media screen and (max-width: 960px) {
  .map {
  width: 75%;
}
}

</style>
