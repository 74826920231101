<template>
  <div class="faq">
    <img class="LogoImg" alt="IMS logo" src="../assets/IMS_Logo.png" />
    <TitleVue msg=""/>
    <div class="content">
      <h1 class="title">| Avant votre rendez-vous au centre Longchamp</h1>

      <div class="contentText">
        <ul>
          <li>N'oubliez pas de vous munir de vos anciens clichés</li>
          <li>Apporter votre carte vitale, vos attestations</li>
          <li>Apporter l'ordonnance de votre médecin</li>
          <li>Soyez attentifs au lieu de votre rendez-vous</li>
          <li>
            Veuillez vous présenter 15 minutes avant l'heure de votre
            rendez-vous pour l'enregistrement administratif
          </li>
        </ul>
      </div>

      <h3>Horaires</h3>
      Cabinet ouvert du Lundi au Vendredi de 8h30 à 18h30, le samedi de 8h30 à
      12h30
      <div class="DoctolibLink">
        <a
          class="rdvButton"
          href="https://www.doctolib.fr/centre-d-imagerie-medicale/suresnes/centre-de-radiologie-longchamp"
          target="_blank"
          rel="noopener"
        >
          Rendez-vous Doctolib
        </a>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import TitleVue from "../components/TitleVue.vue";
export default {
  name: "HomeView",
  components: {
    TitleVue,
  },
};
</script>

<style scoped lang="scss">
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
}



.DoctolibLink {
  margin-top: 40px;
}


p {
  padding-right: 10px;
}
</style>
