<template>
  <img class="LogoImg" alt="IMS logo" src="../assets/IMS_Logo.png" />
  <TitleVue msg=""/>
  <div class="content">
    <h1 class="title">| IRM</h1>
    <ul>
      Nous réalisons nos IRM sur 3 sites distants :
      <br />

      Hôpital Foch (Suresnes), rendez-vous à prendre auprès de notre secrétariat
      <br />
      Centre Hospitalier des 4 villes (Saint-Cloud), rendez-vous en ligne
      <br />
      Centre René Huguenin (Saint-Cloud), rendez-vous en ligne
      <br />
    </ul>
    <br />
    <i class="fa-solid fa-triangle-exclamation"></i> Attention certains examens
    nécessitent une préparation ou une injection

    <div class="info">
      <a
        class="rdvButton"
        href="https://patient.radiologie.fr/fr/examens-en-pratique/irm"
      >
        En savoir plus
      </a>
    </div>
  </div>
  <div class="ContentImg">
    <div class="Image">
      <img alt="Irm du Cerveau" src="../assets/BrainIrm.jpg" />
    </div>
    <div class="Image">
      <img
        alt="Irm du Cerveau coupe Sagital"
        src="../assets/BrainSagital.jpg"
      />
    </div>
    <div class="Image">
      <img alt="IRM Lombaire" src="../assets/Lombaire.jpg" />
    </div>
    <div class="Image">
      <img alt="IRM de genou" src="../assets/KneeIrm.jpg" />
    </div>
  </div>
  <div class="rdv">
    <router-link to="/Rendez-vous" class="rdvButton">
      Rendez-vous en ligne
    </router-link>
    <router-link to="/Contact" class="rdvButton">
      Contacter le secrétariat
    </router-link>
  </div>
</template>

<script>
import TitleVue from "../components/TitleVue.vue";

export default {
  name: "HomeView",
  components: {
    TitleVue,
  },
};
</script>

<style scoped lang="scss">
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

li {
  display: flex;
  align-items: center;
  margin-left: 30%;
}

.rdv {
  margin-top: 5%;
}
.ContentImg {
  margin-top: 30px;
  display: flex;
}

.Image {
  img {
    height: 250px;
  }
}
</style>
