<template>
  <img class="LogoImg" alt="IMS logo" src="../assets/IMS_Logo.png" />
  <TitleVue msg=""/>
  <div class="content">
    <h1 class="title">| Imagerie Dentaire</h1>

    <ul class="ContentList">
      <li class="ListContent">

        <p>| Panoramique Dentaire</p>
      </li>
      <li class="ListContent">

        <p>| Telecrâne</p>
      </li>
      <li class="ListContent">

        <p>| Dental Scanner</p>
      </li>
    </ul>
  </div>
  <div class="ContentImg">
    <div class="Image">
      <img alt="Panoramique Dentaire" src="../assets/Panoramique.png" />
    </div>
    <div class="Image">
      <img alt="Telecrane" src="../assets/Telecrane.png" />
    </div>
  </div>

  <div class="rdv">
    <router-link to="/Rendez-vous" class="rdvButton">
      Prendre un rendez-vous
    </router-link>
  </div>
</template>

<script>
import TitleVue from "../components/TitleVue.vue";

export default {
  name: "HomeView",
  components: {
    TitleVue,
  },
};
</script>

<style scoped lang="scss">

.ListContent {
  display: flex;
}

ul {
  list-style-type: none;
}
.rdv {
  margin-top: 5%;
}

.ContentImg {
  margin-top: 30px;
  display: flex;
}

.Image {
  img {
    height: 300px;
  }
}
</style>
