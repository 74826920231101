<template>
  <div class="home">
    <img class="LogoImg" alt="IMS logo" src="../assets/IMS_Logo.png" />
    <TitleVue msg="" />
    <div id="container">
      <router-link to="/Radiographiegénerale" class="exam"
        >| Radiographie numérisée
      </router-link>
      <router-link to="/EchographieDoppler" class="exam"
        >| Echographie / Doppler
      </router-link>

      <router-link to="/ImagerieSein" class="exam"
        >| Imagerie du sein
      </router-link>
      <router-link to="/Osteo" class="exam">| Ostéodensitométrie </router-link>
      <router-link to="/ImagerieDent" class="exam"
        >| Imagerie dentaire
      </router-link>
      <router-link to="/scanner" class="exam">| Scanner </router-link>
      <router-link to="/IRM" class="exam">| IRM </router-link>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import TitleVue from "../components/TitleVue.vue";

export default {
  name: "HomeView",
  components: {
    TitleVue,
  },
};
</script>
<style scoped lang="scss">
.home {
  height: 1000px;
}
#container {
  width: 80%;
}

.exam {
  display: flex;
  justify-content: space-between;
  padding-top: 5%;
  text-decoration: none;
  font-size: 25px;
  font-weight: bold;
  color: #766e6f;
}
.exam:hover {
  transform: translateY(-10px);
  transition-duration: 0.2s;
}

@media screen and (max-width: 1250px) {
  .exam {
    font-size: 20px;
  }
}

@media screen and (max-width: 960px) {
  .exam {
    font-size: 18px;
  }
}
</style>
