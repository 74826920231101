<template>
  <div class="home">
    <img class="LogoImg" alt="IMS logo" src="../assets/IMS_Logo.png" />
    <TitleVue msg="| FAQ" />
    <ul class="contentList">
      <li class="TitleContent"><h2>| Covid 19</h2></li>
      <div class="TextContent">
        Le Port du masque est obligatoire dans notre centre. <br />
        Pour vos rendez-vous hospitaliers le passe sanitaire est exigé.
      </div>
      <li class="TitleContent">
        <h2>| Ai-je besoin d'une prescription pour faire une radio ?</h2>
      </li>
      <div class="TextContent">
        L'ordonnance est obligatoire pour tout acte d'Imagerie médicale. <br />
        A titre exceptionnel, en cas d'urgence ou pour compléter une demande, le
        radiologue peut être prescripteur.
      </div>
      <li class="TitleContent">
        <h2>| Bien préparer mon examen</h2>
      </li>
      <div class="TextContent">
        <li>Soyez vigilant au lieu de rendez-vous</li>
        <li>
          Présentez vous 15 minutes avant l'horaire de rendez-vous pour
          l'enregistrement administratif
        </li>
        <li>
          Soyez vigilants lors de la prise de rendez-vous aux consignes de
          préparations pour chaque examen
        </li>
        <li>
          (à jeun, vessie pleine, examen biologique à fournir, ordonnance de
          produit de contraste...)
        </li>
        <br />
        <li>Penser à apporter le jour de l'examen :</li>
        <br />
        <li>- l'ordonnance</li>
        <li>- la carte vitale ou attestation de sécurité sociale ou CMU</li>
        <li>- vos anciens examens</li>
      </div>
      <li class="TitleContent">
        <h2>| Contre-indications éventuelles</h2>
      </li>

      <div class="TextContent">
        <li>
          Des précautions peuvent être prises par l'équipe dans les cas suivants
          :
        </li>
        <br />
        <li>- Si vous êtes enceinte ou susceptible de l'être</li>
        <li>
          - Si vous avez déja fait une réaction à une injection de contraste
        </li>
        <li>- Si vous avez un terrain allergique</li>
        <li>- Si vous suivez un traitement anti-coagulant en cas de biopsie</li>
        <li>- Si vous avec un traitement anti-diabétique</li>
        <li>- Si vous avez une insuffisance rénale</li>
        <li>
          - Pour une IRM, si vous êtes porteur d'un pacemaker, d'un
          défibrillateur ou d'une prothèse valvulaire
        </li>
      </div>

      <li class="TitleContent">
        <h2>| Comment obtenir les résultats ?</h2>
      </li>
      <div class="TextContent">
        - Le jour de l'examen sur place dans l'heure. <br />
        - Ou sur
        <a
          href="https://sur1-srvproxy.xplore.fr/xaportaildiffusionmobile/#/LoginPortail/"
          target="_blank"
          rel="noopener"
          >ce site</a
        >
        à l'aide des codes d'accès qui vous ont été remis, via l'onglet
        résultat.
      </div>
    </ul>
  </div>
</template>

<script>
// @ is an alias to /src

import TitleVue from "../components/TitleVue.vue";

export default {
  name: "HomeView",
  components: {
    TitleVue,
  },
};
</script>

<style scoped lang="scss">
.home {
  background-color: #ffffff;
}
.contentList {
  margin-top: 5%;
  padding: 0;
}
.TitleContent {
  margin-bottom: 15px;
  margin-top: 15px;
}

li {
  list-style-type: none;
}
</style>
