<template>
  <div class="home">
    <img class="LogoImg" alt="IMS logo" src="../assets/IMS_Logo.png" />

    <TitleVue msg="| Bienvenue" />
    <div class="welcome">
      Le centre d'imagerie médicale Longchamp vous accueille au 20 avenue du Général de
      Gaulle à Suresnes, Hauts-de-Seine. <br />

      Nos radiologues vous reçoivent pour réaliser tous vos exames d'imagerie.
    </div>
    <div class="rdvresults">
      <div class="rdv">
        <router-link to="/Rendez-vous" class="rdvButton">
          Prendre rendez-vous en ligne
        </router-link>
      </div>
      <div class="rdv">
        <router-link to="/Contact" class="rdvButton">
          Prendre rendez-vous par téléphone
        </router-link>
      </div>

      <div class="rdv">
        <a
          class="rdvButton"
          href="https://sur1-srvproxy.xplore.fr/xaportaildiffusionmobile/#/LoginPortail/"
        >
          Plateforme de résultats
        </a>
      </div>
    </div>
    <div class="ContactUs">
      <h3>Horaires</h3>
      Cabinet ouvert du Lundi au Vendredi de 8h30 à 18h30 sans interruption, le
      samedi de 8h30 à 12h30
      <div class="rdv">
        <router-link to="/Contact" class="rdvButton">
          Nous Contacter
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import TitleVue from "../components/TitleVue.vue";

export default {
  name: "HomeView",
  components: {
    TitleVue,
  },
};
</script>

<style scoped lang="scss">
.home {
  background-color: #ffffff;
}

h2 {
  color: #766e6f;
}
.ImageHome {
  img {
    width: 85%;
    height: 400px;
  }
}

.rdvresults {
  display: flex;
  align-items: center;
}


.rdv {
  margin-top: 10px;
}

.ContactUs {
  margin-top: 10%;
}
</style>
