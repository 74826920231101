<template>
  <div class="home">
    <img class="LogoImg" alt="IMS logo" src="../assets/IMS_Logo.png" />
    <TitleVue msg="| Notre équipe vous accueille" />
    <div class="content">
      <div class="MedicalTeam">
        <h2>Médicale</h2>
        Animée par les Dr SIMON KARNEFF Joëlle et Dr BENAYOUN ZBERRO Sylvie
        <br>ainsi qu'une équipe de radiologues et Doppleristes.
      </div>

      <div class="ParamedicTeam">
        <h2>Paramédicale</h2>
        Composée de nos secrétaires médicales et techniciens en imagerie.
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import TitleVue from "@/components/TitleVue.vue";

export default {
  name: "HomeView",
  components: {
    TitleVue,
  },
};
</script>

<style scoped lang="scss">
.ImageTeam {
  img {
    width: 85%;
    height: 400px;
  }
}

@media screen and (max-width: 599px) {
  
}
</style>
