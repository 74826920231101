<script>
import SidebarLink from "./SidebarLink";
export default {
  props: {},
  components: { SidebarLink },
  setup() {
    return {};
  },
};
</script>

<template>
  <div class="sidebar">
    <h1>
    </h1>

    <SidebarLink to="/" icon="fas fa-home"><h4>Accueil</h4></SidebarLink>
    <SidebarLink to="/examens" icon="fas fa-columns"><h4>Examens</h4></SidebarLink>
    <SidebarLink to="/équipe" icon="fas fa-users"><h4>L'équipe</h4></SidebarLink>
    <SidebarLink to="/contact" icon="fas fa-phone"><h4>Contact</h4></SidebarLink>
    <SidebarLink to="/faq" icon="fas fa-clipboard-question"><h4>FAQ</h4></SidebarLink>

    <div class="bottomBar">
    <img
      alt="IMS logo"
      src="../assets/IMS_LogoWhite.png"
    />
    <p> 20 avenue du Général de Gaulle <br> 92150 Suresnes </p>
  </div>
  </div>
</template>

<style>
:root {
  --sidebar-bg-color: #766e6f;
  --sidebar-item-hover: #b6b6b6;
  --sidebar-item-active: #a1a1a1;
}
</style>

<style scoped>
.bottomBar {
  width: 90%;
  position: absolute;
  bottom: 0;
  left: 0;
  margin-bottom: 5%;
  margin-left: 5%;
}

.sidebar {
  color: white;
  background-color: var(--sidebar-bg-color);
  float: top;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  bottom: 0;
  padding: 0.5em;
  width: 10%;
}
.sidebar h1 {
  height: 2.5em;
}
.sidebar img {
width: 100%;
}
.sidebar p {
  font-size: 12px;
}
.collapse-icon {
  position: relative;
  bottom: 0;
  padding: 0.75em;
  color: #fff;
  transition: 0.2s linear;
}

.rotate-180 {
  transform: rotate(180deg);
  transition: 0.2s linear;
}

@media screen and (max-width: 1250px) {

.sidebar {
  width: 40px;
}

.sidebar h4,p {
 font-size: 0;
}

}

@media screen and (max-width: 500px) {

.sidebar {
  width: 32px;
}

.sidebar h4 {
 font-size: 0;
}

.sidebar p {
  font-size: 7px;
}

}

</style>
