<template>
  <img class="LogoImg" alt="IMS logo" src="../assets/IMS_Logo.png" />
  <TitleVue msg=""/>
  <div class="content">
    <h1 class="title">| Imagerie du Sein</h1>
    <ul class="ContentList">
      <li class="ListContent">
        <p>| Mammographie numérisée basse dose par capteur plan</p>
      </li>
      <li class="ListContent">
        <p>
          | Notre centre est agrée pour le dépistage organisé du cancer du sein
        </p>
      </li>
      <li class="ListContent">
        <p>
          | Echographie Mammaire prescrite par le médecin ou réalisée en
          complément sur prescription du radiologue
        </p>
      </li>
      <li class="ListContent">
        <p>| Prélèvements echoguidés :</p>
      </li>
      <ul>
        <li>- Microbiopsies</li>
        <li>- Cytologie</li>
      </ul>
      <li class="ListContent">
        <p>| IRM Mammaire</p>
      </li>
    </ul>
  </div>
  <div class="ContentImg">
    <div class="Image">
      <img alt="Mammographie" src="../assets/ObliqueD.png" />
    </div>
    <div class="Image">
      <img alt="IRM Mammaire" src="../assets/ObliqueG.png" />
    </div>
  </div>

  <div class="rdv">
    <router-link to="/Rendez-vous" class="rdvButton">
      Prendre un rendez-vous
    </router-link>
  </div>
</template>

<script>
import TitleVue from "../components/TitleVue.vue";

export default {
  name: "HomeView",
  components: {
    TitleVue,
  },
};
</script>

<style scoped lang="scss">
.ListContent {
  display: flex;
}

ul {
  list-style-type: none;
}

.rdv {
  margin-top: 5%;
}

.ContentImg {
  margin-top: 30px;
  display: flex;
}

.Image {
  img {
    height: 300px;
  }
}
</style>
