<template>
  <img class="LogoImg" alt="IMS logo" src="../assets/IMS_Logo.png" />
  <TitleVue msg=""/>
  <div class="content">
    <h1 class="title">| Scanner</h1>
    <div class="contentText">
      Nous réalisons nos Scanner sur 3 sites distants : <br />
      Hôpital Foch (Suresnes)<br />
      Centre Hospitalier des 4 villes (Saint-Cloud)<br />
      Clinique du Val d'or (Saint-Cloud)<br />
      <i class="fa-solid fa-triangle-exclamation"></i> Attention certains
      examens nécessitent une préparation ou une injection
    </div>
  </div>
  <div class="contentImg">
    <img alt="placeholder" src="../assets/ScanAbdo.jpg" />
  </div>
  <div class="rdv">
    <router-link to="/Contact" class="rdvButton">
      Contacter le secrétariat
    </router-link>
  </div>
</template>

<script>
import TitleVue from "../components/TitleVue.vue";

export default {
  name: "HomeView",
  components: {
    TitleVue,
  },
};
</script>

<style scoped lang="scss">
.contentImg {
  margin-top: 30px;

  img {
    height: 300px;
  }
}
.contentText {
  margin-top: 30px;
  width: 80%;

}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

li {
  display: flex;
  margin-left: 30%;
}

.rdv {
  margin-top: 5%;
}
</style>
